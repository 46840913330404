import React, { Component } from "react";
import VehicleDisplay from "./DisplayVehicleInfo";

const EscaladeImages = [
  {
    original: "/images/cadilacfront.png",
    thumbnail: "/images/cadilacfront.png",
  },
  {
    original: "/images/cadillacRear.png",
    thumbnail: "/images/cadillacRear.png",
  },
  {
    original: "/images/cadilacInterior.png",
    thumbnail: "/images/cadilacInterior.png",
  },
  {
    original: "/images/cadilacInterior2.png",
    thumbnail: "/images/cadilacInterior2.png",
  },
];

const EscaladeDetails = [
  "Passenger Max: 7",
  "Exterior Colour: Black",
  "Interior Colour: Black Leather",
  "Air Conditioning & Heating: Yes",
  "Sound System: Yes",
  "Connection: Bluetooth",
  "Baggage: 4+",
];

class Escalade extends Component {
  render() {
    return (
      <VehicleDisplay
        images={EscaladeImages}
        title="Cadillac Escalade"
        details={EscaladeDetails}
      />
    );
  }
}

export default Escalade;
