import React, { Component } from "react";
import VehicleDisplay from "./DisplayVehicleInfo";

const navigatorImages = [
  {
    original: "/images/lincolnFront.png",
    thumbnail: "/images/lincolnFront.png",
  },
  {
    original: "/images/lincolnSide.png",
    thumbnail: "/images/lincolnSide.png",
  },
  {
    original: "/images/lincolnInterior.png",
    thumbnail: "/images/lincolnInterior.png",
  },
  {
    original: "/images/lincolnInterior1.png",
    thumbnail: "/images/lincolnInterior1.png",
  },
];

const navigatorDetails = [
  "Passenger Max: 7",
  "Exterior Colour: Black",
  "Interior Colour: Black Leather",
  "Air Conditioning & Heating: Yes",
  "Sound System: Yes",
  "Connection: Bluetooth",
  "Baggage: 4+",
];

class Navigator extends Component {
  render() {
    return (
      <VehicleDisplay
        images={navigatorImages}
        title="Lincoln Navigator"
        details={navigatorDetails}
      />
    );
  }
}

export default Navigator;
