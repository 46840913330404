import React, { Component } from "react";
import VehicleDisplay from "./DisplayVehicleInfo";

const lexusImages = [
  {
    original: "/images/lexusFront-min.jpg",
    thumbnail: "/images/lexusFront-min.jpg",
  },
  {
    original: "/images/lexus-ls-side.jpg",
    thumbnail: "/images/lexus-ls-side.jpg",
  },
  {
    original: "/images/lexus-ls-rear.jpg",
    thumbnail: "/images/lexus-ls-rear.jpg",
  },
  {
    original: "/images/lexus-int-front.jpg",
    thumbnail: "/images/lexus-int-front.jpg",
  },
  {
    original: "/images/lexus-int-rear.jpg",
    thumbnail: "/images/lexus-int-rear.jpg",
  },
];

const lexusDetails = [
  "Passenger Max: 4",
  "Exterior Colour: Black",
  "Interior Colour: Black Leather",
  "Air Conditioning & Heating: Yes",
  "Sound System: Yes",
  "Connection: Bluetooth",
  "Baggage: 3",
];

class Lexus extends Component {
  render() {
    return (
      <VehicleDisplay
        images={lexusImages}
        title="Lexus LS 500"
        details={lexusDetails}
      />
    );
  }
}

export default Lexus;
