import React, { Component } from "react";
import VehicleDisplay from "./DisplayVehicleInfo";

const chryslerImages = [
  {
    original: "/images/chryslerFront.jpg",
    thumbnail: "/images/chryslerFront.jpg",
  },
  {
    original: "/images/chrysler-rear.png",
    thumbnail: "/images/chrysler-rear.png",
  },
  {
    original: "/images/chrysler-interior.png",
    thumbnail: "/images/chrysler-interior.png",
  },
  {
    original: "/images/chrysler_rearseat.jpg",
    thumbnail: "/images/chrysler_rearseat.jpg",
  },
];

const chryslerDetails = [
  "Passenger Max: 4",
  "Exterior Colour: Black",
  "Interior Colour: Black Leather",
  "Air Conditioning & Heating: Yes",
  "Sound System: Yes",
  "Connection: Bluetooth",
  "Baggage: 3",
];

class Chrysler extends Component {
  render() {
    return (
      <VehicleDisplay
        images={chryslerImages}
        title="Chrysler 300c Platinum"
        details={chryslerDetails}
      />
    );
  }
}

export default Chrysler;
