import React, { Component } from "react";
import "./Privacy.css";

export class Privacy extends Component {
  render() {
    return (
      <div className="privacy-policy-container">
        <h1>Privacy Policy</h1>

        <p>
          At Canadian Limousine, we are committed to protecting your privacy.
          This Privacy Policy explains how we collect, use, and safeguard your
          personal information when you use our services and website.
        </p>

        <h2>1. Information Collection</h2>
        <p>
          We collect information that you provide directly to us, such as when
          you make a reservation, contact our customer service team, or interact
          with us in any other way. This may include your name, email address,
          phone number, payment details, and any other information you choose to
          provide.
        </p>

        <h2>2. Use of Information</h2>
        <p>
          The information we collect is used to provide, maintain, and improve
          our services. This includes using your information to process
          reservations, communicate with you about your bookings, and offer
          customer support.
        </p>

        <h2>3. Information Sharing</h2>
        <p>
          We do not sell or rent your personal information to third parties. We
          may share your information with trusted partners who assist us in
          operating our website, conducting our business, or servicing you, so
          long as those parties agree to keep this information confidential.
        </p>

        <h2>4. Data Security</h2>
        <p>
          We prioritize the security of your personal information. To protect
          your data, we employ advanced security measures, including encryption,
          secure servers, and best practices in information technology. While we
          strive to use commercially acceptable means to protect your personal
          information, we also constantly update our security practices to
          address new challenges and threats, ensuring that your data is handled
          with the utmost care.
        </p>

        <h2>5. Cookies and Tracking Technologies</h2>
        <p>
          Our website may use cookies and similar tracking technologies to
          enhance your experience and gather information about visitors and
          visits to our website.
        </p>

        <h2>6. Consent</h2>
        <p>
          By using our website and services, you consent to our Privacy Policy.
        </p>

        <h2>7. Changes to this Policy</h2>
        <p>
          We may update this Privacy Policy from time to time. We encourage you
          to review it regularly to stay informed about how we are protecting
          the information we collect.
        </p>

        <h2>Contact Us</h2>
        <p>
          If you have any questions or concerns regarding this Privacy Policy,
          please contact us at{" "}
          <a href="mailto:info@canadianlimo.ca">info@canadianlimo.ca</a>.
        </p>
      </div>
    );
  }
}

export default Privacy;
