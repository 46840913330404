import React from "react";
import "../../App.css";
import SuburbanComponent from "../info_components/Suburban";

function Suburban() {
  return (
    <>
      <SuburbanComponent />
    </>
  );
}

export default Suburban;
