import React from "react";
import "../../App.css";
import FleetAll from "../FleetAll";

export default function Fleet() {
  return (
    <>
      <FleetAll />
    </>
  );
}
