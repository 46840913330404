import React, { Component } from "react";
import "./TermsConditions.css";

export class TermsConditions extends Component {
  render() {
    return (
      <div className="terms-conditions-container">
        <h1>Terms and Conditions</h1>

        <h2>1. Introduction</h2>
        <p>
          Welcome to Canadian Limousine. Our commitment to you includes
          providing exceptional service with the highest standards of
          professionalism. By booking our services, you are agreeing to be bound
          by these Terms and Conditions, forming a legally binding contract
          between you and Canadian Limousine.
        </p>

        <h2>2. Service Booking</h2>
        <p>
          <span className="bold-head">2.1. Making a Reservation:</span> You can
          book our limousine services via our website, phone, or email. Our
          customer service team is available from 9 AM to 5 PM for your
          convenience.
        </p>
        <p>
          <span className="bold-head">2.2. Confirmation and Deposit:</span> Upon
          confirming your reservation, a 20% deposit of the total service cost
          is required to secure your booking. This deposit will be applied to
          your final bill. The remaining balance is payable according to the
          agreed payment terms, which will be clearly communicated to you.
        </p>

        <h2>3. Cancellation Policy</h2>
        <p>
          <span className="bold-head">3.1. Full Refund:</span> If you cancel
          your reservation more than 72 hours before the scheduled time, we will
          refund your deposit in full.
        </p>
        <p>
          <span className="bold-head">3.2. Cancellation within 72 Hours:</span>
          For cancellations made less than 72 hours before the booking time, the
          deposit will be non-refundable to cover the potential loss of other
          bookings and preparation costs.
        </p>

        <h2>4. Pricing and Payments</h2>
        <p>
          <span className="bold-head">4.1. Currency and Price Changes:</span>
          All quoted prices are in Canadian Dollars (CAD). Prices are subject to
          change without prior notice, although changes will not affect
          confirmed bookings.
        </p>
        <p>
          <span className="bold-head">4.2. Accepted Payment Methods:</span> We
          accept a variety of payment methods, including cash and major credit
          cards. Specific payment methods available will be indicated during the
          booking process.
        </p>

        <h2>5. Service Alterations</h2>
        <p>
          <span className="bold-head">5.1. Adjustments to Service:</span> In the
          event of unforeseen circumstances, Canadian Limousine reserves the
          right to modify your booking or the allocated vehicle. We will strive
          to provide a vehicle of equal or greater value without any additional
          charges.
        </p>

        <h2>6. Client Responsibilities</h2>
        <p>
          <span className="bold-head">6.1. Conduct in Vehicle:</span> Clients
          are responsible for their conduct and that of their passengers.
          Respectful behavior is expected at all times.
        </p>
        <p>
          <span className="bold-head">6.2. Damage Responsibility:</span> Any
          damage to the vehicle caused by the client or their party will incur
          additional charges, proportional to the cost of repair or cleaning
          services.
        </p>

        <h2>7. Liability</h2>
        <p>
          <span className="bold-head">7.1. Delays:</span> We are not liable for
          delays or service alterations due to external factors, such as traffic
          conditions, weather, or roadworks.
        </p>
        <p>
          <span className="bold-head">7.2. Safety Policy:</span> Your safety is
          our priority. We rigorously adhere to traffic regulations and maintain
          our vehicles to the highest standards.
        </p>

        <h2>8. Privacy Policy</h2>
        <p>
          <span className="bold-head">8.1. Use of Information:</span> The
          information we collect during the booking process is solely for
          service provision. We respect your privacy and will not share your
          personal information with third parties without your consent.
        </p>

        <h2>9. Governing Law</h2>
        <p>
          <span className="bold-head">9.1. Jurisdiction:</span> These Terms and
          Conditions are governed by the laws of Ontario, Canada. Any disputes
          will be subject to the exclusive jurisdiction of the courts of
          Ontario.
        </p>

        <h2>10. Amendments</h2>
        <p>
          <span className="bold-head">10.1. Changes to Terms:</span> We reserve
          the right to modify these Terms and Conditions at any time. The most
          current version will always be posted on our website.
        </p>

        <h2>Contact Us</h2>
        <p>
          For questions or concerns about these Terms and Conditions, please
          reach out to us at{" "}
          <a href="mailto:info@canadianlimo.ca">info@canadianlimo.ca</a>.
        </p>
      </div>
    );
  }
}

export default TermsConditions;
