import React, { Component } from "react";
import "./Contact.css";
import emailjs from "@emailjs/browser";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"; // import this
import { faCheck } from "@fortawesome/free-solid-svg-icons"; // import this

export class Contact extends Component {
  constructor(props) {
    super(props);
    this.form = React.createRef();
    this.state = { submitted: false }; // Add state for submission
  }

  sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_svfo9kq",
        "template_ouf7as4",
        this.form.current,
        "ZqYDn8wftomZ_7OTI"
      )
      .then(
        (result) => {
          console.log(result.text);
          this.form.current.reset(); // Reset the form
          this.setState({ submitted: true }); // Set submitted to true
          setTimeout(() => this.setState({ submitted: false }), 3000); // Reset after 3 seconds
        },
        (error) => {
          console.log(error.text);
        }
      );
  };

  renderSubmitButton() {
    if (this.state.submitted) {
      return (
        <button type="submit" id="contact-btn" disabled>
          <FontAwesomeIcon icon={faCheck} /> Sent
        </button>
      );
    } else {
      return (
        <button type="submit" id="contact-btn">
          Submit
        </button>
      );
    }
  }

  render() {
    return (
      <div id="contact-all">
        <div className="Contact">
          <h1>Contact Us</h1>
          <p>Please feel free to reach out for any inquiries.</p>

          <form
            className="Contact-form"
            ref={this.form}
            onSubmit={this.sendEmail}
          >
            <div className="heading-with-lines">
              <h2>Send us a message</h2>
            </div>
            <div className="form-row">
              <input type="text" placeholder="Name" name="name" />
              <input type="email" placeholder="Email" name="email" />
              <input type="tel" placeholder="Phone Number" name="phone" />
            </div>
            <div className="form-row">
              <textarea placeholder="Questions?" name="message"></textarea>
            </div>
            {this.renderSubmitButton()}
          </form>
        </div>
      </div>
    );
  }
}

export default Contact;
