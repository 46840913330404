import React from "react";
import "../../App.css";
import ContactComponent from "../Contact";

function Contact() {
  return (
    <>
      <ContactComponent />
    </>
  );
}

export default Contact;
