import React, { Component } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUsers, faSuitcase } from "@fortawesome/free-solid-svg-icons";
import "./FleetAll.css";
import { Link } from "react-router-dom";

export class FleetAll extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedCategory: "all",
      dragging: false,
      fleetItems: [
        {
          category: "suvs",
          image: "/images/cadi.png",
          name: "Cadillac Escalade",
          passengers: 7,
          luggage: 4,
          link: "/escalade",
        },
        {
          category: "suvs",
          image: "/images/suburban.png",
          name: "Chevrolet Suburban",
          passengers: 7,
          luggage: 4,
          link: "/suburban",
        },
        {
          category: "sedans",
          image: "/images/mercedesSclass.png",
          name: "Mercedes S-Class",
          passengers: 4,
          luggage: 3,
          link: "/sclass",
        },

        {
          category: "suvs",
          image: "/images/expedition.png",
          name: "Ford Expedition",
          passengers: 7,
          luggage: 4,
          link: "/expedition",
        },
        {
          category: "limos",
          image: "/images/limoStretch.png",
          name: "Lincoln Stretch Limousine",
          passengers: 7,
          luggage: 4,
          link: "/limolincoln",
        },

        {
          category: "vans",
          image: "/images/fordTransit-min.png",
          name: "Ford Transit Van",
          passengers: 14,
          luggage: 12,
          link: "/metris10pass",
        },
        {
          category: "suvs",
          image: "/images/gmc.png",
          name: "GMC Yukon Denali",
          passengers: 7,
          luggage: 4,
          link: "/denali",
        },
        {
          category: "sedans",
          image: "/images/cadillacCTS.png",
          name: "Cadillac CTS",
          passengers: 4,
          luggage: 3,
          link: "/cts",
        },
        {
          category: "vans",
          image: "/images/mercedesSprinter-min.png",
          name: "Mercedes Sprinter Van",
          passengers: 14,
          luggage: 12,
          link: "/metris14pass",
        },
        {
          category: "sedans",
          image: "/images/chrysler.png",
          name: "Chrysler 300c Platinum",
          passengers: 4,
          luggage: 3,
          link: "/chrysler",
        },
        {
          category: "suvs",
          image: "/images/navigator.png",
          name: "Lincoln Navigator",
          passengers: 7,
          luggage: 4,
          link: "/navigator",
        },
        {
          category: "sedans",
          image: "/images/lexus.png",
          name: "Lexus LS 500",
          passengers: 4,
          luggage: 3,
          link: "/lexus",
        },

        // ... Add more items for each category ...
      ],
    };
  }

  selectCategory = (category) => {
    if (!this.state.dragging) {
      this.setState({ selectedCategory: category });
    }
  };

  startDrag = () => {
    this.setState({ dragging: true });
  };

  endDrag = () => {
    this.setState({ dragging: false });
  };

  scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  handleLinkClick = (e) => {
    if (this.state.dragging) {
      e.preventDefault();
    } else {
      this.scrollToTop();
    }
  };

  renderFleetItems = () => {
    const { selectedCategory, fleetItems } = this.state;
    return fleetItems
      .filter(
        (item) =>
          selectedCategory === "all" || item.category === selectedCategory
      )
      .map((item, index) => (
        <div
          className="item-fleet-page-all"
          key={index}
          onMouseDown={this.startDrag}
          onMouseUp={this.endDrag}
        >
          <Link to={item.link} onClick={this.handleLinkClick}>
            {" "}
            {/* Use Link component here */}
            <div className="item-fleet-page">
              <div className="card-fleet-page">
                <img src={item.image} alt={item.name} />
              </div>
              <h4>{item.name}</h4>
              <div className="info-box-page">
                <FontAwesomeIcon icon={faUsers} />
                <span>{item.passengers}</span>
              </div>
              <div className="info-box-page">
                <FontAwesomeIcon icon={faSuitcase} />
                <span>{item.luggage}</span>
              </div>
            </div>
          </Link>
        </div>
      ));
  };

  render() {
    return (
      <>
        <div className="fleet">
          <div className="fleet-heading-page">
            <h1>Our Fleet</h1>
          </div>
        </div>

        <div className="fleet-cat-selector">
          <ul className="fleet-categories">
            <li onClick={() => this.selectCategory("all")}>
              <a href="#all">ALL</a>
            </li>
            <li onClick={() => this.selectCategory("sedans")}>
              <a href="#sedans">SEDANs</a>
            </li>
            <li onClick={() => this.selectCategory("suvs")}>
              <a href="#suvs">SUVs</a>
            </li>
            <li onClick={() => this.selectCategory("limos")}>
              <a href="#limos">LIMOs</a>
            </li>
            <li onClick={() => this.selectCategory("vans")}>
              <a href="#vans">VANs</a>
            </li>
          </ul>
        </div>

        <div className="fleet-items-top-page">
          <div className="fleet-items-container-page">
            {this.renderFleetItems()}
          </div>
        </div>
      </>
    );
  }
}

export default FleetAll;
