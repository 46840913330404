import React, { Component } from "react";
import VehicleDisplay from "./DisplayVehicleInfo";

const denaliImages = [
  {
    original: "/images/gmcFront.png",
    thumbnail: "/images/gmcFront.png",
  },
  {
    original: "/images/gmcRear.png",
    thumbnail: "/images/gmcRear.png",
  },
  {
    original: "/images/gmcInterior2.png",
    thumbnail: "/images/gmcInterior2.png",
  },
  {
    original: "/images/gmcInterior.png",
    thumbnail: "/images/gmcInterior.png",
  },
];

const denaliDetails = [
  "Passenger Max: 7",
  "Exterior Colour: Black",
  "Interior Colour: Black Leather",
  "Air Conditioning & Heating: Yes",
  "Sound System: Yes",
  "Connection: Bluetooth",
  "Baggage: 4+",
];

class Denali extends Component {
  render() {
    return (
      <VehicleDisplay
        images={denaliImages}
        title="GMC Yukon Denali"
        details={denaliDetails}
      />
    );
  }
}

export default Denali;
