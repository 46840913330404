import React, { Component } from "react";
import "./HeroSectionNew.css";
import "./buttons.css";
import Marquee from "react-fast-marquee";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Link } from "react-router-dom";

function NextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block", fontSize: "30px", color: "white" }}
      onClick={onClick}
    >
      <i className="fa fa-chevron-right"></i>
    </div>
  );
}

function PrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block", fontSize: "30px", color: "white" }}
      onClick={onClick}
    >
      <i className="fa fa-chevron-left"></i>
    </div>
  );
}


export class HeroSectionNew extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dragging: false,
    };
  }

  render() {
    const settings = {
      dots: false, // Enables navigation dots
      infinite: true, // Enables infinite looping
      speed: 500,
      slidesToShow: 1, // Show one slide at a time
      slidesToScroll: 1,
      arrows: true,
      nextArrow: <NextArrow />,
      prevArrow: <PrevArrow />,
      autoplay: true,
      beforeChange: () => this.setState({ dragging: true }),
      afterChange: () => this.setState({ dragging: false }),
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            infinite: true,
            dots: true,
          },
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            initialSlide: 1,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    };

     const handleLinkClick = (e) => {
       if (this.state.dragging) {
         e.preventDefault();
       }
     };

    {
      return (
        <>
          <div className="hero-Section">
            <div className="sub-hero-section">
              <div className="row row-1">
                <h1>CANADIAN LIMOUSINE SERVICE</h1>
                <p>
                  Crafting <span className="orangeText">Elite</span> Experience,
                  One <span className="orangeText">Ride</span> at a{" "}
                  <span className="orangeText">Time</span>
                </p>
              </div>
              <div className="row row-2">
                <Link
                  className="btn-outline-link"
                  to="/quote"
                  onClick={handleLinkClick}
                >
                  <button className="btn-outline">GET QUOTE</button>
                </Link>
                <Link
                  className="btn-fill-link"
                  to="/reserve"
                  onClick={handleLinkClick}
                >
                  <button className="btn-fill">RESERVE NOW</button>
                </Link>
              </div>

              <div className="row row-3">
                <Slider {...settings}>
                  <div className="item">
                    <Link to="/escalade" onClick={handleLinkClick}>
                      <img
                        src="/images/cadi.png"
                        alt="Cadillac"
                        className="carousel-image"
                      />
                    </Link>
                  </div>
                  <div className="item">
                    <Link to="/suburban" onClick={handleLinkClick}>
                      <img
                        src="/images/suburban.png"
                        alt="Chevy"
                        className="carousel-image"
                      />
                    </Link>
                  </div>
                  <div className="item">
                    <Link to="/expedition" onClick={handleLinkClick}>
                      <img
                        src="/images/expedition.png"
                        alt="Expedition"
                        className="carousel-image"
                      />
                    </Link>
                  </div>
                  <div className="item">
                    <Link to="/denali" onClick={handleLinkClick}>
                      <img
                        src="/images/gmc.png"
                        alt="GMC"
                        className="carousel-image"
                      />
                    </Link>
                  </div>
                  <div className="item">
                    <Link to="/navigator" onClick={handleLinkClick}>
                      <img
                        src="/images/navigator.png"
                        alt="Lincoln"
                        className="carousel-image"
                      />
                    </Link>
                  </div>
                  <div className="item">
                    <Link to="/chrysler" onClick={handleLinkClick}>
                      <img
                        src="/images/chrysler.png"
                        alt="Chrysler"
                        className="carousel-image"
                      />
                    </Link>
                  </div>
                  <div className="item">
                    <Link to="/metris14Pass" onClick={handleLinkClick}>
                      <img
                        src="/images/mercedesSprinter-min.png"
                        alt="Sprinter Van"
                        className="carousel-image"
                      />
                    </Link>
                  </div>
                  <div className="item">
                    <Link to="/lexus" onClick={handleLinkClick}>
                      <img
                        src="/images/lexus.png"
                        alt="Lexus"
                        className="carousel-image"
                      />
                    </Link>
                  </div>
                  <div className="item">
                    <Link to="/sclass" onClick={handleLinkClick}>
                      <img
                        src="/images/mercedesSclass.png"
                        alt="Mercedes"
                        className="carousel-image"
                      />
                    </Link>
                  </div>
                  <div className="item">
                    <Link to="/metris10Pass" onClick={handleLinkClick}>
                      <img
                        src="/images/fordTransit-min.png"
                        alt="Ford Transit"
                        className="carousel-image"
                      />
                    </Link>
                  </div>
                  <div className="item">
                    <Link to="/limolincoln" onClick={handleLinkClick}>
                      <img
                        src="/images/limoStretch.png"
                        alt="Stretch Limo"
                        className="carousel-image"
                      />
                    </Link>
                  </div>
                </Slider>
              </div>
              <div className="row row-4">
                <div className="marquee-fleet">
                  <Marquee
                    className="marquee"
                    gradient={false}
                    pauseOnHover={true}
                    speed={75}
                  >
                    <h1>TORONTO</h1>
                    <h1>AJAX</h1>
                    <h1>OAKVILLE</h1>
                    <h1>HAMILTON</h1>
                    <h1>MISSISSAUGA</h1>
                    <h1>BRAMPTON</h1>
                    <h1>VAUGHAN</h1>
                    <h1>MARKHAM</h1>
                    <h1>RICHMOND HILL</h1>
                    <h1>PICKERING</h1>
                    <h1>WHITBY</h1>
                    <h1>NEWMARKET</h1>
                    <h1>AURORA</h1>
                    <h1>BURLINGTON</h1>
                    <h1>MILTON</h1>
                    <h1>STOUFFVILLE</h1>
                    <h1>CALEDON</h1>
                    <h1>EAST GWILLIMBURY</h1>
                    <h1>GEORGINA</h1>
                    <h1>KING CITY</h1>
                    <h1>HALTON HILLS</h1>
                    <h1>UXBRIDGE</h1>
                    <h1>SCUGOG</h1>
                    <h1>CLARINGTON</h1>
                    <h1>USA</h1>
                    <h1>CANADA</h1>
                  </Marquee>
                </div>
              </div>
            </div>
          </div>
        </>
      );
    }
  }
}

export default HeroSectionNew;
