import React from "react";
import "../../App.css";
import ReserveComponent from "../Reserve.js";

function Reserve() {
  return (
    <>
      <ReserveComponent />
    </>
  );
}

export default Reserve;
