import React from "react";
import "../../App.css";
import HeroSectionNew from "../HeroSectionNew";
import FleetHome from "../FleetHome";
import Quote from "../Quote";
import OurServices from "../OurServices";






function Home() {
  return (
    <>

      <HeroSectionNew />
      <FleetHome />
      <Quote />
      <OurServices />


    </>
  );
}

export default Home;
