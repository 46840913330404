import React, { Component } from "react";
import VehicleDisplay from "./DisplayVehicleInfo";

const suburbanImages = [
  {
    original: "/images/chevyFront.jpg",
    thumbnail: "/images/chevyFront.jpg",
  },
  {
    original: "/images/chevyRear.jpg",
    thumbnail: "/images/chevyRear.jpg",
  },
  {
    original: "/images/chevyInterior.jpg",
    thumbnail: "/images/chevyInterior.jpg",
  },
  {
    original: "/images/chevyInterior2.jpg",
    thumbnail: "/images/chevyInterior2.jpg",
  },
];

const suburbanDetails = [
  "Passenger Max: 7",
  "Exterior Colour: Black",
  "Interior Colour: Black Leather",
  "Air Conditioning & Heating: Yes",
  "Sound System: Yes",
  "Connection: Bluetooth",
  "Baggage: 4+",
];

class Suburban extends Component {
  render() {
    return (
      <VehicleDisplay
        images={suburbanImages}
        title="Chevrolet Suburban"
        details={suburbanDetails}
      />
    );
  }
}

export default Suburban;
