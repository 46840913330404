import React, { useEffect } from "react";
import "./App.css";
import Navbar from "./components/Navbar";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
} from "react-router-dom";
import Home from "./components/pages/Home";
import Fleet from "./components/pages/Fleet";
import Contact from "./components/pages/Contact";
import Reserve from "./components/pages/Reserve";
import Footer from "./components/Footer";
import Suburban from "./components/vehicle_info_pages/Suburban";
import Quote from "./components/Quote";
import Escalade from "./components/info_components/Escalade";
import Expedition from "./components/info_components/Expedition";
import MercedesSclass from "./components/info_components/MercedesSclass";
import LimoLincoln from "./components/info_components/LimoLincoln";
import Metris10Pass from "./components/info_components/Metris10Pass";
import Denali from "./components/info_components/Denali";
import CTS from "./components/info_components/CTS";
import Chrysler from "./components/info_components/Chrysler";
import Navigator from "./components/info_components/Navigator";
import Lexus from "./components/info_components/Lexus";
import Metris14Pass from "./components/info_components/Metris14Pass";
import TermsConditions from "./components/TermsConditions";
import Privacy from "./components/Privacy";
import CallButton from "./components/CallButton";

function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

function App() {
  return (
    <>
      <Router>
        <Navbar />
        <ScrollToTop />
        <Routes>
          <Route path="/" element={<Home />} exact />
          <Route path="/home" element={<Home />} exact />
          <Route path="/fleet" element={<Fleet />} exact />
          <Route path="/contact" element={<Contact />} exact />
          <Route path="/reserve" element={<Reserve />} exact />
          <Route path="/suburban" element={<Suburban />} exact />
          <Route path="/escalade" element={<Escalade />} exact />
          <Route path="/quote" element={<Quote />} exact />
          <Route path="/expedition" element={<Expedition />} exact />
          <Route path="/sclass" element={<MercedesSclass />} exact />
          <Route path="/limolincoln" element={<LimoLincoln />} exact />
          <Route path="/metris10pass" element={<Metris10Pass />} exact />
          <Route path="/denali" element={<Denali />} exact />
          <Route path="/cts" element={<CTS />} exact />
          <Route path="/chrysler" element={<Chrysler />} exact />
          <Route path="/navigator" element={<Navigator />} exact />
          <Route path="/lexus" element={<Lexus />} exact />
          <Route path="/metris14pass" element={<Metris14Pass />} exact />
          <Route path="/terms" element={<TermsConditions />} exact />
          <Route path="/privacy" element={<Privacy />} exact />
        </Routes>
        <CallButton />
        <Footer />
      </Router>
    </>
  );
}

export default App;
