import React, { Component } from "react";
import VehicleDisplay from "./DisplayVehicleInfo";

const metris10PassImages = [
  {
    original: "/images/FordTransitFront-min.jpg",
    thumbnail: "/images/FordTransitFront-min.jpg",
  },
  {
    original: "/images/fordTransitRear-min.jpg",
    thumbnail: "/images/fordTransitRear-min.jpg",
  },
  {
    original: "/images/fordTransitInterior-min.jpg",
    thumbnail: "/images/fordTransitInterior-min.jpg",
  },
  {
    original: "/images/FordTransitDash-min.png",
    thumbnail: "/images/FordTransitDash-min.png",
  },
];

const metris10PassDetails = [
  "Passenger Max: 14",
  "Exterior Colour: Black",
  "Interior Colour: Black Leather/Fabric",
  "Air Conditioning & Heating: Yes",
  "Sound System: Yes",
  "Connection: Bluetooth",
  "Baggage: 12",
];

class Metris10Pass extends Component {
  render() {
    return (
      <VehicleDisplay
        images={metris10PassImages}
        title="Ford Transit Van"
        details={metris10PassDetails}
      />
    );
  }
}

export default Metris10Pass;
