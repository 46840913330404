// CallButton.js
import React from "react";
import "./CallButton.css"; // Import your CSS file

function CallButton() {
  const handleCall = () => {
    // Open the dialer with the specified phone number
    window.location.href = "tel:+16478014358";
  };

  return (
    <button className="call-button" onClick={handleCall}>
      <i className="fas fa-phone"></i> {/* Font Awesome phone icon */}
    </button>
  );
}

export default CallButton;
