import React, { Component } from "react";
import "./OurServices.css";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

class OurServices extends Component {
  state = {
    windowWidth: window.innerWidth,
  };

  updateDimensions = () => {
    this.setState({ windowWidth: window.innerWidth });
  };

  componentDidMount() {
    window.addEventListener("resize", this.updateDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions);
  }

  renderSmallScreenCarousel = () => {
    return (
      <OwlCarousel
        className="owl-theme small-screen-carousel service-carousel"
        {...this.smallScreenCarouselOptions()}
      >
        {/* Add your 5 images here */}
        <div className="service-carousel">
          <img src="/images/airport.jpg" alt="Image 1" />
          <div className="img-text mobile-serive">Airport Service</div>
        </div>
        <div className="service-carousel">
          <img src="/images/niagara-mobile.jpg" alt="Image 2" />
          <div className="img-text mobile-serive">Niagara Tour</div>
        </div>
        <div className="service-carousel">
          <img src="/images/wedd.jpg" alt="Image 3" />
          <div className="img-text mobile-serive">Wedding</div>
        </div>
        <div className="service-carousel">
          <img src="/images/corp.jpg" alt="Image 4" />
          <div className="img-text mobile-serive">Corporate Service</div>
        </div>
      </OwlCarousel>
    );
  };

  
  smallScreenCarouselOptions = () => {
    return {
      loop: true,
      margin: 10,
      items: 1,
      nav: true,
      dots: false,
      autoplay: false,
      smartSpeed: 1000,
    };
  };

  renderColumns = () => {
    return (
      <>
        <div className="column">
          <div className="icon-row">
            <img src="/images/clock.png" alt="On Time" />
          </div>
          <div className="text-row">
            <p>
              Timeliness is essential to us. We prioritize punctuality, planning
              efficiently to ensure you reach your destination on time, every
              time, without compromising comfort or style.
            </p>
          </div>
        </div>

        <div className="column">
          <div className="icon-row">
            <img src="/images/shield.png" alt="Clean and Reliable" />
          </div>
          <div className="text-row">
            <p>
              Dependability is key in our service. Our fleet is well-maintained
              and our experienced chauffeurs ensure safe, smooth rides, making
              us a trustworthy choice for your travel needs.
            </p>
          </div>
        </div>

        <div className="column">
          <div className="icon-row">
            <img src="/images/clean.png" alt="Professional Chauffeurs" />
          </div>
          <div className="text-row">
            <p>
              We guarantee spotless, sanitized limousines for every journey. Our
              vehicles are meticulously cleaned, providing you with a luxurious
              and comfortable travel experience.
            </p>
          </div>
        </div>
      </>
    );
  };

  render() {
    const { windowWidth } = this.state;
    const options = {
      loop: true,
      margin: 10,
      responsiveClass: true,
      nav: true,
      dots: false,
      autoplay: false,
      smartSpeed: 1000,
      responsive: {
        0: {
          items: 1,
        },
        600: {
          items: 2,
        },
        1000: {
          items: 3,
        },
      },
    };

    return (
      <>
        <div className="services-container">
          <div className="service-box">
            <div className="srv-column">
              <div className="srv-row">
                <h2>Our Services</h2>
                <p className="intro-text">
                  We tailor our limousine services to your unique needs,
                  ensuring a memorable and personalized experience.
                </p>
                {windowWidth < 768 && this.renderSmallScreenCarousel()}
              </div>

              <div className="srv-row desktop-ourService">
                <div className="img-container">
                  <img src="/images/airport.jpg" alt="Airport Service" />
                  <div className="img-text">Airport Service</div>
                </div>
              </div>
            </div>
            <div className="srv-column desktop-ourService">
              <div className="img-container">
                <img src="/images/niagaraTower.jpg" alt="Niagara Tower" />
                <div className="img-text">Niagara Tour</div>
              </div>
            </div>
            <div className="srv-column desktop-ourService">
              <div className="srv-row">
                <div className="img-container">
                  <img src="/images/wedd.jpg" alt="Wedding Service" />
                  <div className="img-text">Wedding Service</div>
                </div>
              </div>
              <div className="srv-row desktop-ourService">
                <div className="img-container">
                  <img src="/images/corp.jpg" alt="Corporate Service" />
                  <div className="img-text">Corporate Service</div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="why-us">
          {windowWidth < 1000 ? (
            <OwlCarousel className="owl-theme why-us-carousel" {...options}>
              {this.renderColumns()}
            </OwlCarousel>
          ) : (
            this.renderColumns()
          )}
        </div>
      </>
    );
  }
}

export default OurServices;
