import React, { Component } from "react";
import VehicleDisplay from "./DisplayVehicleInfo";

const metris14PassImages = [
  {
    original: "/images/SprinterFront-min.jpg",
    thumbnail: "/images/SprinterFront-min.jpg",
  },
  {
    original: "/images/sprinterRear-min.jpg",
    thumbnail: "/images/sprinterRear-min.jpg",
  },
  {
    original: "/images/sprinterInterior-min.jpg",
    thumbnail: "/images/sprinterInterior-min.jpg",
  },
];

const metris14PassDetails = [
  "Passenger Max: 14",
  "Exterior Colour: Black",
  "Interior Colour: Black Leather/Fabric",
  "Air Conditioning & Heating: Yes",
  "Sound System: Yes",
  "Connection: Bluetooth",
  "Baggage: 12",
];

class Metris14Pass extends Component {
  render() {
    return (
      <VehicleDisplay
        images={metris14PassImages}
        title="Mercedes Sprinter Van"
        details={metris14PassDetails}
      />
    );
  }
}

export default Metris14Pass;
