import React, { useRef, useState } from "react";
import "./Quote.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import emailjs from "@emailjs/browser";
import GooglePlacesAutocomplete from "react-google-places-autocomplete";

function Quote() {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    date: "",
    time: "",
    passengers: "",
    carType: "",
    pickUpLocation: null, // Changed to null for object
    dropOffLocation: null, // Changed to null for object
    comments: "",
  });

  const handleLocationChange = (value, field) => {
    setFormData((prevState) => ({
      ...prevState,
      [field]: value, // Store the entire object
    }));
  };


  const [isSubmitted, setIsSubmitted] = useState(false);
  const form = useRef();

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    // Create hidden inputs for the labels from Google Places Autocomplete
    const pickupInput = document.createElement("input");
    pickupInput.type = "hidden";
    pickupInput.name = "pickupLocation";
    pickupInput.value = formData.pickUpLocation
      ? formData.pickUpLocation.label
      : "";
    form.current.appendChild(pickupInput);

    const dropoffInput = document.createElement("input");
    dropoffInput.type = "hidden";
    dropoffInput.name = "dropoffLocation";
    dropoffInput.value = formData.dropOffLocation
      ? formData.dropOffLocation.label
      : "";
    form.current.appendChild(dropoffInput);

    emailjs
      .sendForm(
        "service_svfo9kq",
        "template_bk0v3yh",
        form.current,
        "ZqYDn8wftomZ_7OTI"
      )
      .then(
        (result) => {
          console.log(result.text);
          setIsSubmitted(true);
          // Reset form data
          setFormData({
            name: "",
            email: "",
            phone: "",
            date: "",
            time: "",
            passengers: "",
            carType: "",
            pickUpLocation: null,
            dropOffLocation: null,
            comments: "",
          });
          setTimeout(() => {
            setIsSubmitted(false);
          }, 3000);
        },
        (error) => {
          console.log(error.text);
        }
      );
    // Remove the hidden inputs after submission
    form.current.removeChild(pickupInput);
    form.current.removeChild(dropoffInput);
  };

  return (
    <div className="fleetHome-container">
      <div className="quote-heading">
        <h1 id="header-form">Request a Quote</h1>
      </div>
      <div className="flex-container">
        {/* Form */}
        <div className="form-container">
          <form ref={form} className="fleet-form" onSubmit={handleSubmit}>
            <div className="input-with-icon">
              <i className="fa fa-user"></i>
              <input
                type="text"
                name="name"
                placeholder="Name"
                value={formData.name}
                onChange={handleInputChange}
              />
            </div>

            <div className="input-with-icon">
              <i className="fa fa-envelope"></i>
              <input
                type="email"
                name="email"
                placeholder="Email"
                value={formData.email}
                onChange={handleInputChange}
              />
            </div>
            <div className="second-row">
              <div className="input-with-icon">
                <i className="fa fa-phone"></i>
                <input
                  type="tel"
                  name="phone"
                  placeholder="Phone"
                  value={formData.phone}
                  onChange={handleInputChange}
                />
              </div>
              <div className="second-row-second">
                <label htmlFor="date-input" className="date-time-label">
                  Date
                </label>{" "}
                {/* Add label */}
                <input
                  type="date"
                  name="date"
                  placeholder="Date"
                  value={formData.date}
                  onChange={handleInputChange}
                />
                <label htmlFor="time-input" className="date-time-label">
                  Time
                </label>{" "}
                {/* Add label */}
                <input
                  type="time"
                  name="time"
                  placeholder="Time"
                  value={formData.time}
                  onChange={handleInputChange}
                />
              </div>
            </div>
            <select
              name="passengers"
              value={formData.passengers}
              onChange={handleInputChange}
            >
              <option value="">Select Passengers</option>
              <option value="1-4">1-4</option>
              <option value="5-7">5-7</option>
              <option value="8-10">8-10</option>
              <option value="11-14">11-14</option>
              <option value="14+">14+</option>
            </select>
            <select
              name="vehicle"
              value={formData.vehicle}
              onChange={handleInputChange}
            >
              <option value="">Select Car Type</option>
              <option value="Mercedes Benz Sprinter 10 Passenger">
                Mercedes Benz Sprinter 10 Passenger
              </option>
              <option value="Mercedes Benz Sprinter 14 Passenger">
                Mercedes Benz Sprinter 14 Passenger
              </option>
              <option value="Mercedes Benz Metris">Mercedes Benz Metris</option>
              <option value="Lincoln Town Car Stretch Limo">
                Lincoln Town Car Stretch Limo
              </option>
              <option value="Chrysler 300c">Chrysler 300c</option>
              <option value="Chevy Suburban">Chevy Suburban</option>
              <option value="Cadillac Escalade">Cadillac Escalade</option>
              <option value="Ford Expedition">Ford Expedition</option>
              <option value="GMC Yukon">GMC Yukon</option>
              <option value="Lincoln Navigator">Lincoln Navigator</option>
              <option value="Special Request">Special Request</option>
            </select>

            <div className="input-with-icon locations-input">
              <GooglePlacesAutocomplete
                apiKey="AIzaSyCaSWYazisBS7A1IFxaEm0HDGPF1XOb1c8"
                selectProps={{
                  value: formData.pickUpLocation,
                  onChange: (value) =>
                    handleLocationChange(value, "pickUpLocation"),
                  placeholder: "Pick-up Location",
                  classNamePrefix: "google-places-autocomplete-custom",
                }}
              />
            </div>
            <div className="input-with-icon locations-input-2">
              <GooglePlacesAutocomplete
                apiKey="AIzaSyCaSWYazisBS7A1IFxaEm0HDGPF1XOb1c8"
                selectProps={{
                  value: formData.dropOffLocation,
                  onChange: (value) =>
                    handleLocationChange(value, "dropOffLocation"),
                  placeholder: "Drop-off Location",
                  classNamePrefix: "google-places-autocomplete-custom",
                }}
              />
            </div>
            {/* <i class="fa-regular fa-comments"></i> */}
            <textarea
              name="comments"
              placeholder="Comments"
              value={formData.comments}
              onChange={handleInputChange}
            ></textarea>
            <button type="submit" id="quote-btn">
              {isSubmitted ? <FontAwesomeIcon icon={faCheck} /> : "Submit"}
            </button>
          </form>
        </div>
      </div>
    </div>
  );
}

export default Quote;
