import React, { Component } from "react";
import { Link } from "react-router-dom";
import "./Navbar.css";

export class Navbar extends Component {
  state = {
    isMobileMenuOpen: false,
  };

  componentDidMount() {
    window.addEventListener("resize", this.handleResize);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize);
  }

  handleResize = () => {
    if (window.innerWidth > 768 && this.state.isMobileMenuOpen) {
      this.setState({ isMobileMenuOpen: false });
    }
  };

  toggleMobileMenu = () => {
    this.setState((prevState) => ({
      isMobileMenuOpen: !prevState.isMobileMenuOpen,
    }));
  };

  closeMobileMenu = () => {
    this.setState({ isMobileMenuOpen: false });
  };

  render() {
    const { isMobileMenuOpen } = this.state;

    return (
      <nav className={`navbar ${isMobileMenuOpen ? "open" : ""}`}>
        <div className={isMobileMenuOpen ? "overlay open" : "overlay"}></div>
        <div className="navbar-container">
          <Link to="/home" className="navbar-logo">
            <img src="/images/logo.png" alt="Canadian Limo" />
          </Link>

          <div className="nav-items">
            <ul className="nav-menu">
              <li onClick={this.closeMobileMenu}>
                <Link to="/home" className="nav-link">
                  <span>HOME</span>
                </Link>
              </li>
              <li onClick={this.closeMobileMenu}>
                <Link to="/fleet" className="nav-link">
                  <span>FLEET</span>
                </Link>
              </li>
              <li onClick={this.closeMobileMenu}>
                <Link to="/contact" className="nav-link">
                  <span>CONTACT US</span>
                </Link>
              </li>
              <li onClick={this.closeMobileMenu}>
                <Link to="/reserve" className="nav-button">
                  RESERVE NOW
                </Link>
              </li>
            </ul>

            <div
              className={`menu-icon ${isMobileMenuOpen ? "open" : ""}`}
              onClick={this.toggleMobileMenu}
            >
              <div className="menu-bar"></div>
              <div className="menu-bar"></div>
              <div className="menu-bar"></div>
            </div>
          </div>
        </div>
      </nav>
    );
  }
}

export default Navbar;
