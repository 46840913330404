import React, { Component } from "react";
import "./FleetHome.css";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUsers,
  faSuitcase,
} from "@fortawesome/free-solid-svg-icons";

export class FleetHome extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeCategory: "all",
      fleetData: [
        {
          id: "suvs",
          name: "Cadillac Escalade",
          passengers: 7,
          luggage: 4,
          imageUrl: "/images/cadi.png",
          link: "/escalade",
        },
        {
          id: "suvs",
          name: "Chevrolet Suburban",
          passengers: 7,
          luggage: 4,
          imageUrl: "/images/suburban.png",
          link: "/suburban",
        },
        {
          id: "suvs",
          name: "Ford Expedition",
          passengers: 7,
          luggage: 4,
          imageUrl: "/images/expedition.png",
          link: "/expedition",
        },
        {
          id: "suvs",
          name: "GMC Yukon Denali",
          passengers: 7,
          luggage: 4,
          imageUrl: "/images/gmc.png",
          link: "/denali",
        },
        {
          id: "vans",
          name: "Mercedes Sprinter Van",
          passengers: 14,
          luggage: 12,
          imageUrl: "/images/mercedesSprinter-min.png", // Update with actual image path
          link: "/metris14pass",
        },
        {
          id: "suvs",
          name: "Lincoln Navigator",
          passengers: 7,
          luggage: 4,
          imageUrl: "/images/navigator.png",
          link: "/navigator",
        },
        // Add more fleet items here for other categories like limos, vans, etc.
        // Example for a limo:
        {
          id: "limos",
          name: "Stretch Limousine",
          passengers: 10,
          luggage: 3,
          imageUrl: "/images/limoStretch.png", // Update with actual image path
          link: "/limolincoln",
        },
        // Example for a van:
        {
          id: "vans",
          name: "Ford Transit Van",
          passengers: 14,
          luggage: 12,
          imageUrl: "/images/fordTransit-min.png", // Update with actual image path
          link: "/metris10pass",
        },
        {
          id: "sedans",
          name: "Mercedes S-Class",
          passengers: 4,
          luggage: 3,
          imageUrl: "/images/mercedesSclass.png", // Update with actual image path
          link: "/sclass",
        },
        {
          id: "sedans",
          name: "Chrysler 300C Platinum",
          passengers: 4,
          luggage: 3,
          imageUrl: "/images/chrysler.png", // Update with actual image path
          link: "/chrysler",
        },
        {
          id: "sedans",
          name: "Cadillac CTS",
          passengers: 4,
          luggage: 3,
          imageUrl: "/images/cadillacCTS.png", // Update with actual image path
          link: "/cts",
        },
        {
          id: "sedans",
          name: "Lexux LS 500",
          passengers: 4,
          luggage: 3,
          imageUrl: "/images/lexus.png", // Update with actual image path
          link: "/lexus",
        },
      ],
    };
  }

  handleCategoryChange = (category) => {
    this.setState({ activeCategory: category }, () => {
      // Additional logic if needed after state update
    });
  };

  renderFleetItems() {
    const { fleetData, activeCategory } = this.state;
    return fleetData
      .filter((item) => activeCategory === "all" || item.id === activeCategory)
      .map((item, index) => (
        <Link to={item.link} key={index} className="item-fleet-home-link">
          <div className="item-fleet-home">
            <div className="card-fleet-home">
              <img
                src={item.imageUrl}
                alt={item.name}
                className="carousel-image-fleet-home"
              />
            </div>
            <h4>{item.name}</h4>
            <div className="info-box">
              <FontAwesomeIcon icon={faUsers} />
              <span>{item.passengers}</span>
            </div>
            <div className="info-box">
              <FontAwesomeIcon icon={faSuitcase} />
              <span>{item.luggage}</span>
            </div>
          </div>
        </Link>
      ));
  }

  renderCategoryLink(category, label) {
    const isSelected = this.state.activeCategory === category;

    return (
      <li>
        <a
          href={`#${category}`}
          className={isSelected ? "selected" : ""}
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation(); // Add this line
            this.handleCategoryChange(category);
          }}
        >
          {label}
        </a>
      </li>
    );
  }

  render() {
    const options = {
      loop: false,
      margin: 20,
      dots: false,
      nav: true, // If you want navigation arrows
      responsive: {
        0: {
          items: 1,
          stagePadding: 50,
        },
        600: {
          items: 2,
          stagePadding: 0,
        },
        1000: {
          items: 4,
          stagePadding: 0,
        },
      },
      navText: [
        `<FontAwesomeIcon icon={faChevronLeft} />`,
        `<FontAwesomeIcon icon={faChevronRight} />`,
      ],
    };

    return (
      <>
        <div className="fleet-heading">
          <div className="fleet-heading-col">
            <h2>Our Fleet</h2>
          </div>
          <div className="fleet-heading-col">
            <p>
              Experience luxury travel with our fleet: elegant sedans, SUVs,
              Sprinter Vans and limousines, tailored for your comfort and style.
            </p>
          </div>
        </div>

        <div className="fleet-home unique-fleet-home">
          <div className="carousel-controls unique-carousel-controls">
            <ul className="carousel-categories">
              {this.renderCategoryLink("all", "ALL")}
              {this.renderCategoryLink("sedans", "SEDANs")}
              {this.renderCategoryLink("suvs", "SUVs")}
              {this.renderCategoryLink("limos", "LIMOs")}
              {this.renderCategoryLink("vans", "VANs")}
              {/* ... other categories */}
            </ul>
            <OwlCarousel
              key={this.state.activeCategory}
              className="owl-theme fleet-owl-carousel owl-fleet-home"
              {...options}
            >
              {this.renderFleetItems()}
            </OwlCarousel>
          </div>
        </div>
      </>
    );
  }
}

export default FleetHome;
