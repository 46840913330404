import React, { Component } from "react";
import VehicleDisplay from "./DisplayVehicleInfo";

const ctsImages = [
  {
    original: "/images/cadillacCTS-min.jpg",
    thumbnail: "/images/cadillacCTS-min.jpg",
  },
  {
    original: "/images/cadillacCTSRear.jpg",
    thumbnail: "/images/cadillacCTSRear.jpg",
  },
  {
    original: "/images/cadillacCTSinteriorFront.jpg",
    thumbnail: "/images/cadillacCTSinteriorFront.jpg",
  },
  {
    original: "/images/cadillacCTSinterior.jpg",
    thumbnail: "/images/cadillacCTSinterior.jpg",
  },
];

const ctsDetails = [
  "Passenger Max: 4",
  "Exterior Colour: Black",
  "Interior Colour: Black Leather",
  "Air Conditioning & Heating: Yes",
  "Sound System: Yes",
  "Connection: Bluetooth",
  "Baggage: 3",
];

class CTS extends Component {
  render() {
    return (
      <VehicleDisplay
        images={ctsImages}
        title="Cadillac CTS"
        details={ctsDetails}
      />
    );
  }
}

export default CTS;
