import React, { Component } from "react";
import VehicleDisplay from "./DisplayVehicleInfo";

const ExpeditionImages = [
  {
    original: "/images/ExpeditionFront.png",
    thumbnail: "/images/ExpeditionFront.png",
  },
  {
    original: "/images/expedition-rear.jpg",
    thumbnail: "/images/expedition-rear.jpg",
  },
  {
    original: "/images/expedition-dashboard.jpg",
    thumbnail: "/images/expedition-dashboard.jpg",
  },
  {
    original: "/images/expedition-interior.jpg",
    thumbnail: "/images/expedition-interior.jpg",
  },
];

const ExpeditionDetails = [
  "Passenger Max: 7",
  "Exterior Colour: Black",
  "Interior Colour: Black Leather",
  "Air Conditioning & Heating: Yes",
  "Sound System: Yes",
  "Connection: Bluetooth",
  "Baggage: 4+",
];

class Expedition extends Component {
  render() {
    return (
      <VehicleDisplay
        images={ExpeditionImages}
        title="Ford Expedition"
        details={ExpeditionDetails}
      />
    );
  }
}

export default Expedition;
