import React, { Component } from "react";
import "./Footer.css"; // Assuming you have a CSS file for styling
import { Link } from "react-router-dom";

export class Footer extends Component {
  // Method to handle link click
  handleLinkClick = (event) => {
    event.preventDefault(); // Prevent default link behavior
    window.scrollTo(0, 0); // Scroll to the top of the page

    // If you need to navigate to a different page, use history.push('/path')
    const href = event.currentTarget.getAttribute("href");
    if (href && href.startsWith("/")) {
      // Assuming you're using React Router for navigation
      this.props.history.push(href);
    }
  };

  render() {
    return (
      <>
        <div className="footer">
          <div className="footer-col-1">
            <img src="/images/logo-white-min.png" alt="Canadian Limo" />
            <p className="about-us-paragraph">
              Canadian Limousine &copy; 2023 - Pioneering luxury and
              professionalism in transportation. With a commitment to
              excellence, we provide unparalleled limousine services across
              Canada. Our dedication to safety, comfort, and customer
              satisfaction sets us apart. Connect with us for a superior travel
              experience. <Link to="/terms">Terms & Conditions</Link> |{" "}
              <Link to="/privacy">Privacy Policy</Link> | Crafted with pride by{" "}
              <a href="https://lionsmedia.ca">Lionsmedia.ca</a>.
            </p>
          </div>

          <div className="footer-col">
            <h3>CANADIAN LIMO</h3>
            <ul>
              <li>
                <Link to="/" onClick={this.handleLinkClick}>
                  HOME
                </Link>
              </li>
              <li>
                <Link to="/fleet">FLEET</Link>
              </li>
              <li>
                <Link to="/contact">CONTACT US</Link>
              </li>
              <li>
                <Link to="/reserve">RESERVE NOW</Link>
              </li>
            </ul>
          </div>

          <div className="footer-col">
            <h3>CONTACT US</h3>
            <ul>
              <li>
                <a href="tel:+16478014358">
                  {" "}
                  <i className="fas fa-phone-alt"></i> +1 (647) 801-4358
                </a>
              </li>
              <li>
                <a href="mailto:info@canadianlimo.ca">
                  <i className="fas fa-envelope"></i> info@canadianlimo.ca
                </a>
              </li>
            </ul>
          </div>

          <div className="footer-col">
            <h3>SECURED</h3>
            <div className="payment-icons">
              <i className="fa-brands fa-cc-visa"></i>
              <i className="fa-brands fa-cc-mastercard"></i>
            </div>
          </div>
        </div>

        <div className="footer-bar">
          &copy; 2023 Canadian Limo. All rights reserved. Crafted and designed
          by <a href="https://lionsmedia.ca">Lionsmedia.ca</a>.
        </div>
      </>
    );
  }
}

export default Footer;
