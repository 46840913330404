import React, { Component } from "react";
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";
import "./InfoStyles.css";
import { Link } from "react-router-dom";

class VehicleDisplay extends Component {
  render() {
    const { images, title, details } = this.props;

    return (
      <div className="grid-layout">
        <div>
          <ImageGallery items={images} />
        </div>
        <div className="vehicle-details">
          <h1>{title}</h1>
          <ul>
            {details.map((detail, index) => (
              <li key={index}>{detail}</li>
            ))}
          </ul>
          <Link to="/Quote">
            <button className="quote-btn">Quote</button>
          </Link>
          <Link to="/Reserve">
            <button className="reserve-btn">Reserve</button>
          </Link>
        </div>
      </div>
    );
  }
}

export default VehicleDisplay;
