import React, { Component } from "react";
import "./Reserve.css";
import GooglePlacesAutocomplete from "react-google-places-autocomplete";
import emailjs from "@emailjs/browser";
import { Link } from "react-router-dom";

export class Reserve extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOneWay: true,
      isTwoWay: false,
      passengers: 0,
      baggage: 0,
      serviceHours: 0,
      stops: [],
      screenWidth: window.innerWidth,
      paymentMethod: "",
      formSubmitted: false,
      formKey: 0, // Initialize formKey
      pickupLocation: null, // Changed to null for object
      dropoffLocation: null,
      secondPickupLocation: null,
      secondDropoffLocation: null,
    };
    this.form = React.createRef();
  }

  handleNumberOfStopsChange = (event) => {
    const numberOfStops = event.target.value;
    this.setState({
      stops: Array.from({ length: numberOfStops }, () => null),
    });
  };

  handleStopLocationChange = (value, index) => {
    this.setState((prevState) => {
      const newStops = [...prevState.stops];
      newStops[index] = value;
      return { stops: newStops };
    });
  };

  handleLocationChange = (value, field) => {
    this.setState({ [field]: value }); // Store the entire object
  };

  validateForm = () => {
    const {
      pickupLocation,
      dropoffLocation,
      secondPickupLocation,
      secondDropoffLocation,
      isTwoWay,
    } = this.state;
    if (!pickupLocation || !dropoffLocation) {
      alert("Please fill in both pickup and dropoff locations.");
      return false;
    }
    if (isTwoWay && (!secondPickupLocation || !secondDropoffLocation)) {
      alert(
        "Please fill in both second pickup and dropoff locations for a two-way trip."
      );
      return false;
    }
    return true;
  };

  sendEmail = (e) => {
    e.preventDefault();

    // Validate the form before proceeding
    if (!this.validateForm()) {
      return; // Stop the function if validation fails
    }

    // Create hidden inputs for the labels from Google Places Autocomplete
    const inputs = [
      { name: "pickupLocation", value: this.state.pickupLocation },
      { name: "dropoffLocation", value: this.state.dropoffLocation },
      { name: "secondPickupLocation", value: this.state.secondPickupLocation },
      {
        name: "secondDropoffLocation",
        value: this.state.secondDropoffLocation,
      },
    ];

    inputs.forEach((input) => {
      const hiddenInput = document.createElement("input");
      hiddenInput.type = "hidden";
      hiddenInput.name = input.name;
      hiddenInput.value = input.value ? input.value.label : "";
      this.form.current.appendChild(hiddenInput);
    });

    // Concatenate all stop locations into a single string
    const stopsString = this.state.stops
      .map((stop, index) =>
        stop
          ? `Stop ${index + 1}: ${stop.label}`
          : `Stop ${index + 1}: Not specified`
      )
      .join(", ");

    // Create a hidden input for stops
    const stopsInput = document.createElement("input");
    stopsInput.type = "hidden";
    stopsInput.name = "stops";
    stopsInput.value = stopsString;
    this.form.current.appendChild(stopsInput);

    // Send the form data with EmailJS
    emailjs
      .sendForm(
        "service_svfo9kq",
        "template_bk0v3yh",
        this.form.current,
        "ZqYDn8wftomZ_7OTI"
      )
      .then(
        (result) => {
          console.log(result.text);
          this.setState({
            formSubmitted: true,
            formKey: this.state.formKey + 1,
          });

          // Add a timeout to reset the form after 3 seconds
          setTimeout(() => {
            this.clearForm();
          }, 3000);

          // Remove the hidden inputs after submission
          inputs.forEach((input) => {
            this.form.current.removeChild(
              document.getElementsByName(input.name)[0]
            );
          });
          this.form.current.removeChild(stopsInput);
        },
        (error) => {
          console.log(error.text);
        }
      );
  };

  clearForm = () => {
    this.setState(
      {
        isOneWay: true,
        isTwoWay: false,
        passengers: 0,
        baggage: 0,
        serviceHours: 0,
        numberOfStops: 0, // Initialize number of stops
        stops: [],
        screenWidth: window.innerWidth,
        paymentMethod: "",
        name: "", // Assuming you have a 'name' field
        email: "", // Assuming you have an 'email' field
        phone: "", // Assuming you have a 'phone' field
        date: "", // Assuming you have a 'date' field
        time: "", // Assuming you have a 'time' field
        vehicle: "", // Assuming you have a 'vehicle' selection
        serviceRequired: "", // Assuming you have a 'serviceRequired' selection
        pickupLocation: "", // Assuming you have a 'pickupLocation' field
        dropoffLocation: "", // Assuming you have a 'dropoffLocation' field
        secondPickupLocation: "", // Assuming you have a 'secondPickupLocation' field
        secondDropoffLocation: "", // Assuming you have a 'secondDropoffLocation' field
        cardHolderName: "", // Assuming you have a 'cardHolderName' field
        cardNumber: "", // Assuming you have a 'cardNumber' field
        expiryMonth: "", // Assuming you have an 'expiryMonth' field
        expiryYear: "", // Assuming you have an 'expiryYear' field
        cvv: "", // Assuming you have a 'cvv' field
        termsAgreement: false, // Reset the agreement checkbox
      },
      () => {
        // This callback function ensures that the state is updated before executing further logic
        setTimeout(() => {
          this.setState({ formSubmitted: false }); // Reset formSubmitted after a delay
        }, 2000);
      }
    );
  };

  componentDidMount() {
    window.addEventListener("resize", this.handleResize);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize);
  }

  handleResize = () => {
    this.setState({ screenWidth: window.innerWidth });
  };

  handleOneWayToggle = () => {
    this.setState((prevState) => ({
      isOneWay: !prevState.isOneWay,
      isTwoWay: prevState.isOneWay ? true : false,
    }));
  };

  handleTwoWayToggle = () => {
    this.setState((prevState) => ({
      isTwoWay: !prevState.isTwoWay,
      isOneWay: prevState.isTwoWay ? true : false,
    }));
  };

  increment = (field, event) => {
    event.preventDefault();
    this.setState((prevState) => ({
      ...prevState,
      [field]: prevState[field] + 1,
    }));
  };

  decrement = (field, event) => {
    event.preventDefault();
    if (this.state[field] > 0) {
      this.setState((prevState) => ({
        ...prevState,
        [field]: prevState[field] - 1,
      }));
    }
  };

  handleInputChange = (event) => {
    const { name, value } = event.target;
    this.setState({ [name]: value });
  };
  render() {
    const {
      isOneWay,
      isTwoWay,
      passengers,
      baggage,
      screenWidth,
      paymentMethod,
    } = this.state;

    const handlePaymentMethodChange = (event) => {
      this.setState({ paymentMethod: event.target.value });
    };
    const radioGroup = (
      <div className="form-column">
        <div className="radio-group-container">
          <label className="radio-group-title">Preferred Contact</label>
          <div className="radio-group">
            <input
              type="radio"
              id="email"
              name="contactPref"
              value="email"
              className="radio-input"
            />
            <label htmlFor="email" className="radio-label">
              Email
            </label>

            <input
              type="radio"
              id="phone"
              name="contactPref"
              value="phone"
              className="radio-input"
            />
            <label htmlFor="phone" className="radio-label">
              Phone
            </label>

            <input
              type="radio"
              id="both"
              name="contactPref"
              value="both"
              className="radio-input"
              defaultChecked
            />
            <label htmlFor="both" className="radio-label">
              Both
            </label>
          </div>
        </div>
      </div>
    );

    return (
      <>
        <div className="all">
          <div className="heading-title-reserve">
            <h1>
              Book Your <span className="white">Experience,</span> with{" "}
              <span className="white">Confidence</span>
            </h1>
          </div>
          <div className="reserve-container" key={this.state.formKey}>
            <div className="form-header">
              <h2>RESERVE</h2>
            </div>

            <form
              className="reservation-form"
              ref={this.form}
              onSubmit={this.sendEmail}
            >
              <div className="form-column icon">
                <i className="fa fa-user"></i>
                <input
                  type="text"
                  name="name"
                  placeholder="Full Name"
                  required
                />
              </div>
              <div className="form-column icon">
                <i class="fa-solid fa-envelope"></i>
                <input type="email" name="email" placeholder="Email" required />
              </div>
              <div className="form-column icon">
                <i class="fa-solid fa-phone"></i>
                <input
                  type="tel"
                  name="phone"
                  placeholder="Phone No."
                  required
                />
              </div>
              {screenWidth < 768 && radioGroup}
              <div className="row-four-columns">
                <div className="column">
                  <label
                    htmlFor="date-input"
                    className="date-time-label-reserve"
                  >
                    Date
                  </label>
                  <input type="date" name="date" required />
                </div>
                <div className="column">
                  <label
                    htmlFor="date-input"
                    className="date-time-label-reserve"
                  >
                    Time
                  </label>
                  <input type="time" name="time" required />
                </div>
              </div>
              <div className="form-column icon remove-icon">
                <i class="fa-solid fa-car"></i>
                <select name="vehicle" required>
                  <option value="">Select Car Type</option>
                  <option value="Mercedes Benz Sprinter 10 Passenger">
                    Mercedes Benz Sprinter 10 Passenger
                  </option>
                  <option value="Mercedes Benz Sprinter 14 Passenger">
                    Mercedes Benz Sprinter 14 Passenger
                  </option>
                  <option value="Mercedes Benz Metris">
                    Mercedes Benz Metris
                  </option>
                  <option value="Lincoln Town Car Stretch Limo">
                    Lincoln Town Car Stretch Limo
                  </option>
                  <option value="Chrysler 300c">Chrysler 300c</option>
                  <option value="Chevy Suburban">Chevy Suburban</option>
                  <option value="Cadillac Escalade">Cadillac Escalade</option>
                  <option value="Ford Expedition">Ford Expedition</option>
                  <option value="GMC Yukon">GMC Yukon</option>
                  <option value="Lincoln Navigator">Lincoln Navigator</option>
                  <option value="Special Request">Special Request</option>
                </select>
              </div>
              <div className="form-column icon remove-icon">
                <i class="fa-solid fa-calendar-check"></i>
                <select name="serviceRequired" required>
                  <option value="" disabled selected hidden>
                    Service Required
                  </option>
                  <option value="airport">Airport</option>
                  <option value="wedding">Wedding</option>
                  <option value="corporate">Corporate Service</option>
                  <option value="nightout">Night Out</option>
                  <option value="livery">Livery</option>
                  <option value="birthday">Birthday Party</option>
                  <option value="niagara">Niagara Falls Tour</option>
                  <option value="other">Other</option>
                </select>
              </div>
              <div className="form-column icon number-input-horizontal">
                <i class="fa-solid fa-hourglass-start"></i>
                <button
                  className="minus-horizontal"
                  onClick={(e) => this.decrement("serviceHours", e)}
                >
                  -
                </button>
                <input
                  type="number"
                  name="serviceHours"
                  placeholder="Hours Required"
                  value={
                    this.state.serviceHours > 0 ? this.state.serviceHours : ""
                  }
                  onChange={this.handleInputChange}
                />
                <button
                  className="plus-horizontal"
                  onClick={(e) => this.increment("serviceHours", e)}
                >
                  +
                </button>
              </div>
              <div className="row-four-columns">
                <div className="column icon number-input">
                  <i className="fa-solid fa-users"></i>
                  <button
                    className="minus"
                    onClick={(e) => this.decrement("passengers", e)}
                  >
                    -
                  </button>
                  <input
                    type="number"
                    name="passengers"
                    placeholder="Passengers"
                    value={passengers > 0 ? passengers : ""}
                    onChange={this.handleInputChange}
                  />
                  <button
                    className="plus"
                    onClick={(e) => this.increment("passengers", e)}
                  >
                    +
                  </button>
                </div>
                <div className="column icon number-input">
                  <i className="fa-solid fa-suitcase-rolling"></i>
                  <button
                    className="minus"
                    onClick={(e) => this.decrement("baggage", e)}
                  >
                    -
                  </button>
                  <input
                    type="number"
                    name="baggage"
                    placeholder="Baggages"
                    value={baggage > 0 ? baggage : ""}
                    onChange={this.handleInputChange}
                  />
                  <button
                    className="plus"
                    onClick={(e) => this.increment("baggage", e)}
                  >
                    +
                  </button>
                </div>
              </div>
              {screenWidth >= 768 && radioGroup}
              <div id="toggle-btn" className="form-column">
                <div className="toggle-buttons-container">
                  <div className="toggle-button">
                    <input
                      type="checkbox"
                      id="one-way"
                      name="oneWay"
                      className="toggle-input"
                      checked={isOneWay}
                      onChange={this.handleOneWayToggle}
                    />
                    <label for="one-way" className="toggle-label"></label>
                    <span className="toggle-label-text">One-Way Trip</span>
                  </div>
                  <div className="toggle-button">
                    <input
                      type="checkbox"
                      id="two-way"
                      name="twoWay"
                      className="toggle-input"
                      checked={isTwoWay}
                      onChange={this.handleTwoWayToggle}
                    />
                    <label for="two-way" className="toggle-label"></label>
                    <span className="toggle-label-text">Two-Way Trip</span>
                  </div>
                </div>
              </div>

              <div className="form-column icon">
                <GooglePlacesAutocomplete
                  apiKey="AIzaSyCaSWYazisBS7A1IFxaEm0HDGPF1XOb1c8"
                  selectProps={{
                    value: this.state.pickupLocation,
                    onChange: (value) =>
                      this.handleLocationChange(value, "pickupLocation"),
                    placeholder: "Pick-up Location",
                  }}
                />
              </div>
              <div className="form-column icon">
                <GooglePlacesAutocomplete
                  apiKey="AIzaSyCaSWYazisBS7A1IFxaEm0HDGPF1XOb1c8"
                  selectProps={{
                    value: this.state.dropoffLocation,
                    onChange: (value) =>
                      this.handleLocationChange(value, "dropoffLocation"),
                    placeholder: "Drop-off Location",
                  }}
                />
              </div>

              <div className="form-column">
                <select
                  name="numberOfStops"
                  value={this.state.stops.length}
                  onChange={this.handleNumberOfStopsChange}
                >
                  <option value="0">No. Stops</option>
                  <option value="1">1 Stop</option>
                  <option value="2">2 Stops</option>
                  <option value="3">3 Stops</option>
                  <option value="4">4 Stops</option>
                  <option value="5">5 Stops</option>
                </select>
              </div>

              {this.state.stops.map((_, index) => (
                <div key={index} className="form-column icon">
                  <GooglePlacesAutocomplete
                    apiKey="AIzaSyCaSWYazisBS7A1IFxaEm0HDGPF1XOb1c8"
                    selectProps={{
                      value: this.state.stops[index],
                      onChange: (value) =>
                        this.handleStopLocationChange(value, index),
                      placeholder: `Stop ${index + 1} Location`,
                    }}
                  />
                </div>
              ))}

              {this.state.isTwoWay && (
                <div className="full-width-row">
                  <div className="form-column icon">
                    <GooglePlacesAutocomplete
                      apiKey="AIzaSyCaSWYazisBS7A1IFxaEm0HDGPF1XOb1c8"
                      selectProps={{
                        value: this.state.secondPickupLocation,
                        onChange: (value) =>
                          this.handleLocationChange(
                            value,
                            "secondPickupLocation"
                          ),
                        placeholder: "Second Pick-up Location",
                      }}
                    />
                  </div>

                  <div className="form-column icon">
                    <GooglePlacesAutocomplete
                      apiKey="AIzaSyCaSWYazisBS7A1IFxaEm0HDGPF1XOb1c8"
                      selectProps={{
                        value: this.state.secondDropoffLocation,
                        onChange: (value) =>
                          this.handleLocationChange(
                            value,
                            "secondDropoffLocation"
                          ),
                        placeholder: "Second Drop-off Location",
                      }}
                    />
                  </div>
                </div>
              )}
              <div className="form-column">
                <select
                  name="paymentMethod"
                  value={paymentMethod}
                  onChange={handlePaymentMethodChange}
                  required
                >
                  <option value="" disabled selected hidden>
                    Payment Method
                  </option>
                  <option value="cash">Cash</option>
                  <option value="credit">Credit Card</option>
                </select>
              </div>
              {paymentMethod === "credit" && (
                <div className="credit-card-form">
                  {/* Cardholder's Full Name */}
                  <div className="form-column creditCard">
                    <input
                      type="text"
                      name="cardHolderName"
                      placeholder="Cardholder's Full Name"
                      required
                    />
                  </div>

                  {/* Card Number */}
                  <div className="form-column creditCard">
                    <input
                      type="text"
                      name="cardNumber"
                      placeholder="Card Number"
                      required
                    />
                  </div>

                  {/* Expiry Date */}
                  <div className="form-row creditCard">
                    <div className="form-column half-width">
                      <input
                        type="text"
                        name="expiryMonth"
                        placeholder="MM"
                        maxLength="2"
                        required
                      />
                    </div>
                    <div className="form-column half-width">
                      <input
                        type="text"
                        name="expiryYear"
                        placeholder="YYYY"
                        maxLength="4"
                        required
                      />
                    </div>
                  </div>

                  {/* CVV */}
                  <div className="form-column creditCard">
                    <input
                      type="text"
                      name="cvv"
                      placeholder="CVV"
                      maxLength="3"
                      required
                    />
                  </div>

                  {/* Agreement Checkbox */}
                  <div className="form-column full-width agreement-section">
                    <label className="agreement-checkbox">
                      <input type="checkbox" name="termsAgreement" required />
                      <span className="custom-checkbox"></span>I agree to
                      Canadian Limousine, Inc.,&nbsp;
                      <Link to="/terms">Terms and Conditions</Link>
                      &nbsp;&amp;&nbsp;
                      <Link to="/privacy">Privacy Policy</Link>.
                    </label>
                  </div>

                  {/* Payments & Deposits Information */}
                  <div className="form-column full-width payment-info">
                    <p>
                      <strong>Payments & Deposits:</strong> A 20% deposit is
                      required to hold a reservation or a full payment is
                      required if the reservation falls within the 14 days to
                      the event.
                    </p>
                  </div>

                  {/* Cancellation Policy */}
                  <div className="form-column full-width cancellation-policy">
                    <p>
                      <strong>Cancellation:</strong> After submitting the form
                      and upon processing the deposit amount, you will have 72
                      hours to cancel for a complete refund.
                    </p>
                  </div>
                </div>
              )}
              <button type="submit">
                {this.state.formSubmitted ? (
                  <i className="fas fa-check"></i> // Checkmark icon
                ) : (
                  "Submit"
                )}
              </button>
            </form>
          </div>
        </div>

        <div className="additional-info">
          <h2>Additional Information</h2>
          <div>
            <h3>Harmonized Sales Tax (HST) Information:</h3>
            <p>All rates include a 13% Harmonized Sales Tax (HST).</p>
          </div>
          <div>
            <h3>Driver’s Gratuity:</h3>
            <p>
              A 15% gratuity for the driver is automatically added to all
              reservations.
            </p>
          </div>
          <div>
            <h3>Additional Charges for Extra Stops:</h3>
            <p>Lincoln Town Car Sedan: $10.00 plus tax per extra stop.</p>
            <p>6 Passenger Limo/SUV: $15.00 plus tax per extra stop.</p>
            <p>
              8 Passenger/12 Passenger Stretch Limo: $25.00 plus tax per extra
              stop.
            </p>
          </div>
          <div>
            <h3>Toll Highway 407 ETR Fees:</h3>
            <p>
              If Toll Highway 407 ETR is used, applicable toll fees will be
              added.
            </p>
          </div>
          <div>
            <h3>Additional Fee for Toronto Pearson Airport Pickups:</h3>
            <p>Lincoln Town Car Sedan and 6 Passenger SUV: $15.00.</p>
            <p>Stretch Limos: $27.00.</p>
            <p>Limo/Coach Bus (up to 40 Passengers): $60.00.</p>
            <p>
              Note: This fee is levied by Toronto Pearson Airport Authorities.
            </p>
          </div>
          <div>
            <h3>Cancellation Policy:</h3>
            <p>
              No charge for waiting time at the airport due to flight delays.
            </p>
            <p>
              For pickups from other locations (e.g., home, office, hotel), a
              $10.00 charge applies for every 15 minutes of waiting time.
            </p>
            <p>
              Changes require 24-hour notice, or a 25% rescheduling fee of the
              base fare applies.
            </p>
            <p>
              Cancellations for local pickups require 24-hour notice, and
              cross-border pickups require 48-hour notice, or a 50% cancellation
              fee of the base fare applies.
            </p>
            <p>
              Cancellations at the time of scheduled pickup incur a 100% fare
              charge plus taxes.
            </p>
            <p>No-shows are charged 100% of the fare plus taxes.</p>
            <p>
              Avoid using alternative taxi/limo services at the scheduled pickup
              time to prevent a no-show charge.
            </p>
          </div>
        </div>
      </>
    );
  }
}

export default Reserve;
