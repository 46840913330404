import React, { Component } from "react";
import VehicleDisplay from "./DisplayVehicleInfo";

const limoLincolnImages = [
  {
    original: "/images/limolincolnFront-min.jpg",
    thumbnail: "/images/limolincolnFront-min.jpg",
  },
  {
    original: "/images/lincolnLimoInterior.png",
    thumbnail: "/images/lincolnLimoInterior.png",
  },
];

const limoLincolnDetails = [
  "Passenger Max: 10",
  "Exterior Colour: Black",
  "Interior Colour: Black Leather",
  "Air Conditioning & Heating: Yes",
  "Sound System: Yes",
  "Connection: Bluetooth",
  "Baggage: 3",
];

class LimoLincoln extends Component {
  render() {
    return (
      <VehicleDisplay
        images={limoLincolnImages}
        title="Lincoln Stretch Limousine"
        details={limoLincolnDetails}
      />
    );
  }
}

export default LimoLincoln;
