import React, { Component } from "react";
import VehicleDisplay from "./DisplayVehicleInfo";

const MercedesSclassImages = [
  {
    original: "/images/mercedesSclassFront-min.jpg",
    thumbnail: "/images/mercedesSclassFront-min.jpg",
  },
  {
    original: "/images/mercedesSclassRear-min.jpg",
    thumbnail: "/images/mercedesSclassRear-min.jpg",
  },
  {
    original: "/images/mercedes-Benz-S-Class-interior-front.jpg",
    thumbnail: "/images/mercedes-Benz-S-Class-interior-front.jpg",
  },
  {
    original: "/images/mercedes-Benz-S-Class-interior.jpg",
    thumbnail: "/images/mercedes-Benz-S-Class-interior.jpg",
  },
];

const MercedesSclassDetails = [
  "Passenger Max: 4",
  "Exterior Colour: Black",
  "Interior Colour: Black Leather",
  "Air Conditioning & Heating: Yes",
  "Sound System: Yes",
  "Connection: Bluetooth",
  "Baggage: 3",
];

class MercedesSclass extends Component {
  render() {
    return (
      <VehicleDisplay
        images={MercedesSclassImages}
        title="Mercedes S-Class"
        details={MercedesSclassDetails}
      />
    );
  }
}

export default MercedesSclass;
